.hero {
  background-image: url(../../../assets/gradient.webp);
  font-family: "Poppins", sans-serif;
  height: 100vh;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position-y: 100%;
  color: #192830;

  overflow: hidden;
  @media (max-width: 1200px) {
    // height: 100vh;
  }
  @media (max-width: 1024px) {
    // height: 60vh;
  }
  @media (max-width: 768px) {
    // height: fit-content;
    // height: ;
    background-image: url(../../../assets/gradient.webp);
  }

  @media (min-height: 840px) and (max-width: 768px) {
    // height: fit-content;
    height: 100vh;
    background-image: url(../../../assets/gradient.webp);
  }

  // @media (max-width: 1440px) {
  //   background-image: url(../../../assets/gradient.webp);
  //   background-position-y: 100%;
  // }

  @media (max-width: 768px) and (min-height: 1024px) {
    height: 50vh;
  }
  @media (max-width: 375px) {
    height: 100vh;
  }

  .hero-mockup {
    display: none;

    @media (max-width: 768px) {
      display: block;
      height: 324px;
      width: 90%;
    }
  }

  .ctd {
    display: none;

    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }
}

.mockup-hero {
  display: flex;

  justify-content: center;
  // height: 100%;
  width: 100%;
  position: absolute;
  padding: 12px;
  z-index: 0;
  // height: 400px;
  // background-color: #192830;
  /* Portrait */
  @media (min-width: 768px) {
    align-items: flex-end;
    bottom: 0;
  }
  .hero-mockup-image {
    // height: 525px;
    // width: 100vw;
    height: 60vh;
    z-index: 99;
    // @media (min-width: 768px) and (orientation: landscape) {
    //   height: 70vh;
    // }
    /* Landscape */
    // @media (max-width: 1200px) and (orientation: portrait) {
    //   height: 50vh;
    // }

    // @media (min-width: 1440px) {
    //   height: 20vh;
    // }
    @media (max-width: 1440px) {
      // display: block;
      // width: 50vh;
      // height: 20vh;
    }

    // @media (max-width: 1024px) {
    //   // height: 200px;
    // }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .hero-mockup-background {
    position: absolute;
    height: 50vh;
    z-index: 1;

    @media (min-width: 1440px) {
      // height: 425px;
      // height: 500px;
    }

    // @media (max-width: 1024px) {
    //   display: none;
    //   z-index: 0;
    //   height: 25vh;
    // }
    @media (max-width: 768px) {
      display: none;
      z-index: 0;
      // height: 25vh;
    }
  }

  .mockup-hero-mobile {
    color: #fff !important;
    z-index: 99;

    .hero-mockup-image-mobile {
      display: none;

      @media (max-width: 768px) {
        display: block;
        height: 35vh;
        margin: auto;
      }
    }

    .mobile-text {
      display: none;
      // position: absolute;
      // height: 100%;
      margin-top: 100px;
      // z-index: -1;

      @media (max-width: 768px) {
        display: block;
        padding: 20px 16px;
        // padding-bottom: 50px;
        text-transform: uppercase;
        margin-bottom: 18px;
      }

      // @media (max-width: 768px) {
      //   display: block;
      //   padding: 12px 16px;
      //   text-transform: uppercase;
      //   margin-bottom: 18px;
      // }

      /* Styles for iPhone SE */
      @media only screen and (max-width: 375px) {
        /* Apply specific styles for iPhone SE */
        // display: none;
      }

      /* Styles for iPhone 5 */
      @media only screen and (max-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
        /* Apply specific styles for iPhone 5 */
        display: none;
      }

      h2 {
        font-size: 32px;
        @media only screen and (max-width: 375px) {
          /* Apply specific styles for iPhone SE */
          // display: none;
          font-size: 28px;
        }
      }

      h3 {
        font-size: 24px;
        @media only screen and (max-width: 375px) {
          /* Apply specific styles for iPhone SE */
          // display: none;
          // font-size: 4vw;
          // line-height: 1rem;
        }
        font-weight: 600;
      }
    }
  }
}

.hero-text-desktop {
  display: none;
  // margin: 20px 0;
  // z-index: 99;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 80%;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    // height: 30vh;
    text-align: center;
    text-transform: uppercase;

    h1 {
      font-size: 3.5vw;
      line-height: 3rem;
    }

    h2 {
      font-size: 2vw;
      font-weight: 500;
    }
  }

  @media (min-height: 768px) {
    display: block;
    top: 12rem;
  }
  @media (max-width: 768px) {
    display: none;
    top: 10rem;
  }
}
