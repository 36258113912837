.details {
  // height: 100vh;
  width: 100%;
  background-color: #eaf7ff;
  font-family: "Poppins", sans-serif;
  padding-top: 128px;

  @media (max-width: 768px) {
    padding-top: 64px;
  }

  .details-text {
    padding: 32px;

    @media (max-width: 768px) {
      padding: 24px;
    }

    h2 {
      font-size: 36px;
      line-height: 48px;
      margin: 24px 0;
      position: relative;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100px;
        left: 0px;
        bottom: -12px;
        height: 3px;
        background-color: black;
      }
    }

    p {
      width: 70%;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding: 12px 0;

      @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

.mobile-footer {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.desk-footer {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}
