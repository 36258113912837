.select-container {
  display: flex;
  width: 100%;
  .custom-select {
    position: relative;
    display: inline-block;
    width: 100%;

    flex-grow: 1;

    .select-trigger {
      position: relative;
      padding: 8px 16px;
      background-color: #f3f3f3;
      border: 1px solid #ddd;
      border-radius: 4px;
      width: 100%;
      outline: none;
      border: none;
      height: 64px;
      border-radius: 12px;
      background: #ffffff;
      border-radius: 16px;
      // padding: 16px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;

      span {
        margin-right: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        padding: 12px 0;
        opacity: 0.75;
      }

      img {
        position: absolute;
        top: 50%;
        right: 24px;
        height: 16px;
        width: 16px;
        transform: translateY(-50%);
      }
    }

    .options {
      display: none;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background-color: #fff;
      border: 1px solid #ddd;
      border-top: none;
      border-radius: 0 0 4px 4px;
      list-style: none;
      padding: 0;
      margin: 0;

      z-index: 999;

      li {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          background-color: #eaf7ff;
        }
      }
    }

    &.open .options {
      display: block;
    }
  }
}
