.MuiModal-root {
  border-radius: 20px;
}

.menu {
  background-color: #baf8f0;
  overflow-y: scroll;

  // touch-action: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // height: fit-content;
  z-index: 999999999999;
  // padding: 16px;
  font-family: "Poppins", sans-serif;
  color: #192830;
  padding-top: 96px;

  @media (min-width: 768px) {
    padding-top: 64px;
  }

  .menu-download {
    display: none;

    @media (max-width: 768px) {
      display: block;
      padding: 0px 16px;
    }

    .stores {
      span {
        white-space: normal;
        padding: 0;
      }
    }
  }

  .menu-nav {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .content {
    padding: 16px 24px;
    padding-bottom: 0;

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 24px;
    }

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    .menu-block {
      // overflow-y: scroll;

      @media (min-width: 1024px) {
        padding: 64px 24px;
      }

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;

        @media (min-width: 768px) {
          font-size: 32px;
          line-height: 48px;
        }
      }

      ul {
        li {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin: 8px 0;

          @media (min-width: 768px) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .menu-social {
    background-color: #fff;
    margin: 16px;
    border-radius: 24px;
    padding: 12px;

    display: flex;
    gap: 12px;
    padding-bottom: 0;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: fit-content;
      padding: 16px 48px;
      margin: 0 16px;
    }

    @media (min-height: 800px) {
      display: none;
      margin: 0 16px;
    }

    @media (min-width: 1024px) {
      width: fit-content;
      padding: 16px 48px;
      gap: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(50%);
      display: flex;
      left: 36px;
    }

    .socials {
      .footer-social {
        display: flex;
        gap: 12px;
        padding: 12px 0;

        .social {
          height: 36px;
          width: 36px;
          border-radius: 50%;
          background-color: #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          a {
            all: unset;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 18px;
              width: 18px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .localisation {
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  .Menu-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .menu-close-btn {
    position: absolute;
    left: 48px;
    height: 24px;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1024px) {
      top: 32px;
      left: 24px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .menu-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;

    @media (min-width: 768px) and (max-width: 1024px) {
      top: 18px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}

#offre-emploies {
  position: relative;
}

#bientot {
  /* Bleu-Gray */

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 12px;
  padding: 4px 12px;
  position: absolute;
  right: -75%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #192830;
}

#nos-services-menu {
  position: relative;
  padding-right: 12px;
}
