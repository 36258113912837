.contact {
  min-height: 100vh;
  width: 100%;
  background-color: #eaf7ff;
  font-family: "Poppins", sans-serif;
  padding-top: 128px;

  @media (max-width: 768px) {
    padding-top: 64px;
  }

  .contact-content {
    padding: 32px;

    @media (max-width: 768px) {
      padding: 24px;
    }

    h2 {
      font-size: 36px;
      line-height: 48px;
      margin: 24px 0;
      position: relative;
      width: fit-content;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100px;
        left: 0px;
        bottom: -12px;
        height: 3px;
        background-color: black;
      }
    }

    .contact-p {
      width: 85%;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding: 12px 0;

      @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
        padding: 12px 0;
      }
    }

    form {
      .form-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px 0;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 12px;
        }
      }

      .form-input {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-right: 12px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          padding-right: 0px;
        }
      }

      .form-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      input,
      select {
        outline: none;
        border: none;
        height: 64px;
        background: #ffffff;
        border-radius: 16px;
        padding: 0px 16px;
        font-family: Poppins, sans-serif;
      }

      textarea {
        resize: none;
        border-radius: 16px;
        border: none;
        padding: 16px;
        font-family: Poppins, sans-serif;

        @media (max-width: 768px) {
          height: 250px;
        }
      }

      textarea:focus {
        outline: none !important;
        border: 3px solid #baf8f0;
        // box-shadow: 0 0 10px #baf8f0;
      }

      button {
        background-color: #baf8f0;
        padding: 12px 24px;
        border-radius: 48px;
        float: right;
        margin: 16px 0;
        cursor: pointer;

        span {
          color: #3fcebe;
          font-weight: 600;
        }

        @media (max-width: 768px) {
          float: none;
          width: 100%;
          padding: 12px 0;
          text-align: center;
        }
      }
    }

    .contact-mail {
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }

      img {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.mobile-footer {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.desk-footer {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 200px;
}

.select-trigger {
  position: relative;
  padding: 8px 16px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.select-trigger span {
  margin-right: 8px;
}

.select-trigger i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.options li {
  padding: 8px 16px;
  cursor: pointer;
}

.options li:hover {
  background-color: #ddd;
}

.invalid-input {
  border: 1px solid #ff416c !important;

  &::placeholder {
    color: #ff416c;
  }
}

.error-message {
  color: #ff416c;
  margin: 6px;

  @media (min-width: 1024px) {
    position: absolute;
  }
}

.input-container {
  position: relative;
  width: 100%;

  input {
    width: 100%;
  }
}
