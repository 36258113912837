.copyrights {
    // height: 100vh;
    width: 100%;
    background-color: #EAF7FF;
    font-family: "Poppins", sans-serif;
    padding-top: 128px;

    @media (max-width:768px) {
        padding-top: 64px;
    }

    .copyright-text {
        padding: 32px;

        @media (max-width:768px) {
            padding: 24px;
        }


        h2 {
            font-size: 36px;
            line-height: 48px;
            margin: 24px 0;
            position: relative;

            @media (max-width:768px) {
                font-size: 24px;
                line-height: 32px;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100px;
                left: 0px;
                bottom: -12px;
                height: 3px;
                background-color: black;

            }
        }

        p {
            width: 85%;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            padding: 24px 0;

            @media (max-width:768px) {
                width: 100%;
                font-size: 16px;
                padding: 12px 0;
            }

        }

        h4 {

            @media (max-width:768px) {
                padding: 6px 0;
            }

            span {
                text-transform: uppercase;
            }
        }
    }
}


.mobile-footer {
    display: none;

    @media (max-width:768px) {
        display: block;
    }
}


.desk-footer {
    display: block;

    @media (max-width:768px) {
        display: none;
    }
}