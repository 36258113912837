.navbar-container {
  padding: 24px 36px;
  font-family: "Poppins", sans-serif;
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 10;

  @media (max-width: 1024px) {
    padding: 24px 16px;
  }

  .navbar {
    background-color: white;
    height: fit-content;
    border-radius: 12px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #192830;

    font-family: "Poppins", sans-serif;

    position: relative;

    @media (max-width: 1024px) {
      padding: 12px;
      height: 64px;
    }

    .navbar-left {
      display: flex;
      align-items: center;
      gap: 12px;

      cursor: pointer;

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      img {
        @media (max-width: 1024px) {
          height: 32px;
          width: 32px;
        }
      }
    }

    .navbar-center {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 124px;
      }

      span {
        font-size: 24px;
        font-weight: 600;

        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
    }

    .navbar-right {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (max-width: 1024px) {
        display: none;
      }

      .languages {
        display: flex;
        // gap: 12px;
        cursor: pointer;

        .MuiTypography-root {
          border-left: 2px solid #192830;
          padding: 0 12px;
          text-transform: uppercase;

          &:first-child {
            border-left: 0px;
          }
        }

        /* .language {
          border-left: 2px solid #192830;
          padding: 0 12px;
          text-transform: uppercase;

          &:first-child {
            border-left: 0px;
          }
        } */
      }

      .cta-button {
        padding: 0px 24px;
        color: #3fcebe;
        background: #baf8f0;
        border-radius: 12px;
        height: 48px;
        font-weight: 600;
      }

      .btn_download {
        padding: 0px 24px;
        color: #3fcebe;
        background: #baf8f0;
        border-radius: 12px;
        height: 48px;
        font-weight: 600;
        transition-duration: 0.2s;
        display: inline-block;
        perspective: 1000px;
        transform-origin: center bottom 0px;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }

      .btn_download:hover {
        background: #3fcebe;
        color: white;
      }

      .pressed {
        transform: matrix(0.95, 0, 0, 0.95, 0, 0);
      }
    }
  }

  .active_language {
    font-weight: 900;
    text-transform: uppercase;
  }
}

.close-btn-image {
  @media (max-width: 1024px) {
    height: 24px !important;
    width: 24px !important;
  }

  .popover {
    box-shadow: 1px 1px 15px 5px rgba(156, 2, 97, 0.916);
  }
}
