.download-wrapper {
  background-color: #eaf7ff;
  width: 100%;
  font-family: "Poppins";
  color: #192830;
  padding-top: 64px;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    padding-top: 32px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    width: 1024px;
    margin: auto;
    font-family: "Poppins";

    @media (max-width: 768px) {
      font-size: 24px;
      text-align: center;
      width: 100%;
      line-height: 36px;
      margin-bottom: 64px;
      padding: 0px 32px;
    }
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    padding: 64px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }

    .download-call {
      height: 196px;
      border-radius: 24px;
      width: fit-content;
      background-color: white;
      display: flex;
      align-items: center;
      width: 700px;

      @media (max-width: 768px) {
        display: none;
      }

      .qr-code {
        padding: 18px;
        height: 100%;

        img {
          height: 100%;
          object-fit: contain;
        }
      }

      .download-text {
        position: relative;

        img {
          position: absolute;
          height: 48px;
          width: 96px;
          top: -50%;
          left: -24px;
        }

        p {
          line-height: 24px;
          padding: 8px;
          padding-left: 0px;
        }
      }
    }

    .app-stores {
      height: 196px;
      border-radius: 24px;
      background-color: white;
      width: 196px;
      margin: 0 12px;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;

      @media (max-width: 768px) {
        display: none;
      }

      .app-store-link {
        cursor: pointer;
      }

      .app-store {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 50px;
        justify-content: center;
        border-radius: 50px;
        padding: 6px;
        // margin: 12px;
        cursor: pointer;
        background-color: #000;

        img {
          height: 32px;
          width: 32px;
        }

        .app-store-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          span,
          p {
            color: white;
            line-height: 16px;
            font-size: 14px;
          }

          p {
            font-weight: 200;
            font-size: 9px;
            line-height: 12px;
            text-transform: uppercase;
          }
        }
      }
    }

    .available-stores {
      display: none;

      @media (max-width: 768px) {
        display: block;
        background-color: white;
        height: 324px;
        width: 100%;
        border-radius: 24px;
        padding: 24px;
      }

      h3 {
        text-align: center;
        position: relative;

        &::after {
          background-color: #000;
          content: "";
          height: 2px;
          width: 20%;
          position: absolute;
          left: 0;
          top: 50%;
        }

        &::before {
          background-color: #000;
          content: "";
          height: 2px;
          width: 20%;
          position: absolute;
          right: 0;
          top: 50%;
        }
      }

      .available-store {
        .app-store {
          padding: 16px;
          margin: 12px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          background-color: #000 !important;
          justify-content: center;
          gap: 4px;
          color: #fff;

          .text {
            height: 32px;
            display: flex;
            flex-direction: column;
            color: #fff !important;

            p {
              text-transform: uppercase;
              font-size: 12px;
              color: #fff !important;
              line-height: 12px;
            }

            h4 {
              color: #fff !important;
              font-weight: 400;
            }
          }
        }

        img {
          height: 32px;
          width: 32px;
        }
      }
    }

    .landscape {
      display: none;

      @media (max-width: 768px) {
        display: block;
        background-color: white;
        height: 184px;
        width: 100%;
        border-radius: 18px;
        margin: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mooshir-app-description {
    width: 100%;
    // height: 675px;
    overflow: hidden;
    padding: 12px 36px;
    position: relative;

    @media (min-width: 1440px) {
      height: 960px;
    }

    @media (max-width: 768px) {
      height: 550px;
      // border-radius: 24px;
      border-radius: 24px;
      width: 90%;
      margin: 0 auto;
      padding: 24px;
      padding: 0px;
      overflow: hidden;
    }

    .mooshir-app-description-content {
      height: 768px;
      border-radius: 36px;
      background-image: url(../../../assets/noisy-gradient.webp);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      color: #192830;
      overflow: hidden;

      @media (min-width: 1440px) {
        height: 924px;
        width: 80%;
        margin: 0 auto;
      }

      @media (max-width: 768px) {
        height: 550px;
        background-image: url(../../../assets/noisy-gradient-mobile.webp);
      }

      .mobile-app-logo {
        position: absolute;
        top: 36px;
        left: 48px;
        background-color: white;
        padding: 24px;
        border-radius: 12px;

        @media (max-width: 768px) {
          top: 24px;
          left: 64px;
        }
      }

      .mobile-app-landscape {
        position: absolute;
        top: 32px;
        right: 48px;
        background-color: white;
        padding: 6px;
        border-radius: 12px;
        width: 196px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          display: none;
        }

        img {
          height: 90%;
          object-fit: contain;
        }
      }

      .mooshir-app-description-text {
        gap: 24px;
        height: 100%;
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .description-text {
          padding-top: 96px;
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 6px;

          @media (max-width: 768px) {
            padding-top: 154px;
          }

          h2 {
            font-size: 36px;
            text-transform: uppercase;

            @media (max-width: 768px) {
              font-size: 28px;
              margin-bottom: 0;
              text-align: start;
              padding: 0;
            }
          }

          h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 38px;
            text-transform: uppercase;

            @media (max-width: 768px) {
              font-size: 22px;
              line-height: 24px;
            }
          }
        }

        img {
          position: absolute;
          bottom: 0px;
          width: 70%;

          @media (max-width: 768px) {
            width: 100%;
            height: 50%;
            object-fit: cover;
          }

          @media (min-width: 1440px) {
            width: 1024px;
          }
        }
      }
    }
  }
}
