.second-call-to-download {
  background-color: #eaf7ff;
  font-family: "Poppins ", sans-serif;
  padding-top: 24px;

  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    padding: 64px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }

    .download-call {
      height: 196px;
      border-radius: 24px;
      width: "fit-content";
      background-color: white;
      display: flex;
      align-items: center;
      width: 700px;

      @media (max-width: 768px) {
        display: none;
      }

      .qr-code {
        padding: 18px;
        height: 100%;

        img {
          height: 100%;
          object-fit: contain;
        }
      }

      .download-text {
        position: relative;
        font-weight: bold;

        img {
          position: absolute;
          height: 48px;
          width: 96px;
          top: -50%;
          left: -24px;
        }

        p {
          line-height: 24px;
          padding: 8px;
          padding-left: 0px;
          font-weight: 400;
        }
      }
    }

    .app-stores {
      height: 196px;
      border-radius: 24px;
      background-color: white;
      width: 196px;
      margin: 0 12px;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;

      @media (max-width: 768px) {
        display: none;
      }

      .app-store-link {
        cursor: pointer;
      }

      .app-store {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 50px;
        justify-content: center;
        border-radius: 50px;
        padding: 6px;
        // margin: 12px;
        cursor: pointer;
        background-color: #000;

        img {
          height: 32px;
          width: 32px;
        }

        .app-store-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          span,
          p {
            color: white;
            line-height: 16px;
            font-size: 14px;
          }

          p {
            font-weight: 200;
            font-size: 9px;
            line-height: 12px;
            text-transform: uppercase;
          }
        }
      }
    }

    .available-stores {
      display: none;

      @media (max-width: 768px) {
        display: block;
        background-color: white;
        height: 324px;
        width: 100%;
        border-radius: 24px;
        padding: 24px;
      }

      h3 {
        text-align: center;
        position: relative;

        &::after {
          background-color: #000;
          content: "";
          height: 2px;
          width: 20%;
          position: absolute;
          left: 0;
          top: 50%;
        }

        &::before {
          background-color: #000;
          content: "";
          height: 2px;
          width: 20%;
          position: absolute;
          right: 0;
          top: 50%;
        }
      }

      .available-store {
        .app-store {
          padding: 16px;
          margin: 12px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          background-color: #000 !important;
          justify-content: center;
          gap: 4px;
          color: #fff;

          .text {
            height: 32px;
            display: flex;
            flex-direction: column;
            color: #fff !important;

            p {
              text-transform: uppercase;
              font-size: 12px;
              color: #fff !important;
              line-height: 12px;
            }

            h4 {
              color: #fff !important;
              font-weight: 400;
            }
          }
        }

        img {
          height: 32px;
          width: 32px;
        }
      }
    }

    .landscape {
      display: none;

      @media (max-width: 768px) {
        display: block;
        background-color: white;
        height: 184px;
        width: 100%;
        border-radius: 18px;
        margin: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
