.modal-wrapper {
  background: rgba(14, 27, 37, 0.97);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;

  .download-modal {
    background-color: #fff;
    // min-width: 600px;
    margin: 16px;
    height: fit-content;
    max-height: 90%;
    border-radius: 36px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;

    .modal-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // height: 75px;
      padding: 12px;

      button {
        font-size: 24px;
        background-color: #000;
        height: 48px;
        width: 48px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-body {
      @media (min-width: 1440px) {
        padding: 0 16px;
      }

      .qr-code {
        display: block;
        height: 124px;
        margin: 0 auto;

        @media (min-width: 1440px) {
          height: 164px;
        }
      }

      h2 {
        text-align: center;
        padding: 12px;
        line-height: 24px;
      }

      p {
        text-align: center;
      }

      // .buttons {
      //   // height: 150px;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   gap: 8px;

      //   // width: 80%;
      //   padding: 24px 0;
      //   // width: 85%;
      //   // background-color: red;
      //   margin: 0 auto;

      //   .buttons-grid {
      //     display: grid;
      //     justify-content: center;
      //     grid-template-columns: repeat(3, 1fr);
      //     gap: 8px;
      //     width: 80%;
      //     // height: 75px;

      //     button {
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       gap: 6px;
      //       padding: 6px;
      //       border-radius: 8px;
      //       background-color: #000;
      //       color: #fff;
      //       min-height: 36px;

      //       .text {
      //         display: flex;
      //         flex-direction: column;
      //         align-items: flex-start;
      //         justify-content: center;

      //         span {
      //           opacity: 0.75;
      //           font-size: 12px;
      //           white-space: nowrap;
      //           color: #fff;
      //         }

      //         p {
      //           white-space: nowrap;
      //           color: #fff;
      //           font-size: 16px;
      //           line-height: 16px;
      //         }
      //       }
      //     }
      //   }
      // }

      .buttons {
        // height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        // width: 80%;
        padding: 24px 0;
        // width: 85%;
        // background-color: red;
        margin: 0 auto;

        .buttons-grid {
          display: grid;

          justify-content: center;
          align-items: center;
          grid-template-columns: repeat(3, 1fr);
          gap: 8px;
          // width: 80%;
          height: 75px;
          @media only screen and (max-width: 800px) {
            display: block;
            height: fit-content;
          }
          .app-store-link {
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              padding: 6px;
              margin: 10px;
              border-radius: 50px;
              background-color: #000;
              color: #fff;
              min-height: 55px;
              min-width: 230px;
              // width: 100%;
              .text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                span {
                  opacity: 0.75;
                  font-size: 12px;
                  white-space: nowrap;
                  color: #fff;
                }

                p {
                  white-space: nowrap;
                  color: #fff;
                  font-size: 16px;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }

      .mooshir-app-img {
        object-fit: contain;
        width: 550px;
        // background-color: red;
        // width: 100%;
        // height: 100%;

        // padding-top: 16px;
        position: relative;
        // left: -24px;
        display: block;
        margin: 0 auto;
        @media only screen and (max-width: 800px) {
          display: none;
        }
        @media (min-width: 1440px) {
          top: 24px;
        }
      }
    }
  }
}
