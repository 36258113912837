.services {
  background-color: #eaf7ff;
  font-family: "Poppins", sans-serif;
  padding: 100px 0;
  padding-bottom: 16px;
  color: #192830;

  @media (max-width: 768px) {
    padding: 16px 0;
  }
  .title {
    @media (max-width: 768px) {
      padding: 15% 3%;
    }

    p {
      text-align: center;
      padding: 12px;
      font-style: normal;
      font-weight: 400;
      // font-size: 32px;
      font-size: 50px;
      // line-height: 48px;
      // background-color: #192830;
      letter-spacing: 0.01em;
      padding: 0;

      span {
        text-transform: uppercase;
        font-weight: bold;
      }

      @media (max-width: 768px) {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        width: 100%;
      }
    }
  }

  .images {
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 32px;
    gap: 0px;
    height: 60vh;
    margin: 124px 0;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      flex-direction: column;
      height: fit-content;
      gap: 12px;
      margin: 32px 0;
      padding: 0 12px;
    }

    .mobile-image {
      @media (min-width: 768px) {
        display: none;
      }
    }

    img {
      height: 100%;
      width: 33%;
      object-fit: contain;

      @media (max-width: 768px) {
        width: 100%;
        height: 424px;
        object-fit: cover;
        border-radius: 18px;
      }

      &:nth-child(2) {
        object-fit: contain;
        height: 75%;

        @media (max-width: 768px) {
          order: -1;
          height: 250px;
        }
      }

      &:nth-child(3) {
        object-fit: contain;
        position: relative;
        top: -36px;

        @media (max-width: 768px) {
          height: 75%;
          display: none;
        }
      }

      &:nth-child(1) {
        object-position: right;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.image-right {
  object-position: left;
}
