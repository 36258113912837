.footer-container {
  padding: 16px 12px;
  background-color: #eaf7ff;

  footer {
    font-family: "Poppins", sans-serif;
    border-radius: 16px;
    background-color: #fefefe;
    padding: 16px 0;
    padding-bottom: 0;

    .logo {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 12px;

      img {
        height: 64px;
      }

      p {
        line-height: 12px;
        white-space: nowrap;
        font-style: normal;
        font-size: 14px;
      }
    }

    .download {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 90%;
        height: 1px;
        background-color: #eee;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        width: 90%;
        height: 1px;
        background-color: #eee;
      }

      button {
        background-color: #000;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        gap: 12px;
        border-radius: 12px;
        color: #fff;
      }

      .stores {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 6px;

        button {
          color: #fff;
          gap: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          padding: 6px 0;

          img {
            height: 100%;
            width: 90%;
          }
        }
      }
    }

    .socials {
      padding: 24px 16px;

      .footer-social {
        display: flex;
        gap: 12px;
        padding: 12px 0;

        .social {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          a {
            all: unset;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 24px;
              width: 24px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
