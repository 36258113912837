.container-carousel {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  .carousel {
    display: flex;
    width: fit-content;

    position: relative;

    // height: 100px;
    // left: 220px;
  }
}
