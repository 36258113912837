.show-case {
  // background-color: black;
  font-family: "Poppins", sans-serif;
  // margin: 100px 0px;
  // height: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullScreen {
  width: 100%;
  height: 100%;
  // position: absolute;
  top: 0;
  left: 0;
}

.second-row {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
  background-color: #f107a3;
}
.parent {
  // background: black;
  // background-image: url(../../../assets/noisy-gradient.webp);
  backdrop-filter: blur(50px);
  // background-color: rgba(255, 255, 255, 0.7);
  width: 70%;
  height: fit-content;
  // min-width: 500px;
  // position: fixed;
  // height: 700px;
  border-radius: 25px;
  padding: auto;
  // display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    // height: 500px;
    width: 100%;
    // border-radius: 25px;
    // border-radius: 0px;
    border-radius: 0;

    line-height: 16px;
  }
  .play-image {
    width: 100px;
    height: 100px;
    // background-color: #f107a3;
  }

  // position: relative;
  .embed-container {
    position: relative;
    padding-top: 56.25%;

    height: 0;
    // width: 500px;
    border-radius: 25px;
    overflow: hidden;
    max-width: 100%;

    @media (max-width: 768px) {
      position: relative;
      padding-left: 50%;

      width: 100%;
      // width: 500px;
      // border-radius: 25px;
      overflow: hidden;
      height: 100vh;
    }
  }
  .showCase_title {
    position: absolute;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    color: #fff;
    width: 100%;
    height: 80%;
    z-index: 999999;
    // background-color: #f107a3;
    .glass-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 120px;
      z-index: 9999999;
      border: 0;
      text-decoration: none;
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(100px);
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      letter-spacing: 2px;
      cursor: pointer;
      text-transform: uppercase;
    }

    .glass-button:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title_container_mobile {
    position: relative;

    border-radius: 25px;
    overflow: hidden;
    max-width: 100%;

    width: 100%;

    height: 100%;

    // position: relative;

    .showCase_title_mobile {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      width: 100%;
      height: 100%;
      z-index: 999999;
      margin-bottom: 50px;
      h1 {
        font-size: 48px;
        line-height: 4rem;
        width: 70%;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 32px;
          line-height: 3rem;
          width: 90%;
        }
      }
    }
  }
}

.parent[data-isOpen="true"] {
  width: 100%;
  height: 100%;
}

.child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  // border-radius: 50%;
}

.video-vimeo {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // position: absolute;
  object-fit: cover;
  // object-fit: cover;

  // pointer-events: none;
}

.video-player {
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  .glass-button {
    display: flex;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 100px;
    width: 64px;
    height: 64px;
    // padding: 24px 32px;
    z-index: 999999999999;
    border: 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(100px);
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    letter-spacing: 2px;
    // cursor: pointer;
    // text-transform: uppercase;
  }

  .glass-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .close-image {
    width: 24px;
    height: 24px;
    // background-color: #f107a3;
  }
}
.video-embed-object-fit-cover > iframe {
  position: absolute;
  /* keep video centered both vertically and horizontally */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  overflow: hidden;
  /* force aspect ratio based on parent container */
  @container (min-aspect-ratio: 16/9) {
    height: 56.25cqw;
  }

  @container (max-aspect-ratio: 16/9) {
    width: 177.78cqh;
  }

  /* prevent interaction */
  pointer-events: none;
}
