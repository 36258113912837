.design-container {
  width: 75%;
  margin: 100px auto;
  // background-color: #eaf7ff;
  font-family: "Poppins", sans-serif;
  @media (max-width: 768px) {
    width: 90%;
  }

  h1 {
    width: 100%;
    // padding: 24px;
    @media (min-width: 1266px) {
      // width: 1240px;
    }

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    // line-height: 75px;
    margin-bottom: 96px;
    padding: 0 10%;
    @media (max-width: 768px) {
      font-size: 30px;
      // text-align: center;
      width: 100%;

      padding: 0;

      line-height: 36px;

      // padding: 0 24px;
    }
  }
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  // padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-top: 50px;

  margin-bottom: var(--cardMargin);
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--cardTopPadding));
  // margin-top: 50px;
}

.card-body {
  box-sizing: border-box;
  // padding: 30px;
  border-radius: 20px;
  // box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // height: var(--cardHeight);
  height: 90vh;
  width: 100%;
  padding: 0 10%;
  flex: 1;
  display: flex;
  // flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0;
    height: 100vh;
  }
  .image-right-section {
    // background-color: #6600ff;
    width: 40%;
    height: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
    // position: relative;
    // flex-direction: column;
    display: flex;
    padding-left: 24px;
    align-items: flex-end;
    justify-content: center;
    // background: var(--white);
    overflow: hidden;
    .img-mockup {
      // position: absolute;

      height: 80vh;
      width: 100%;
      // width: 400px;
      margin-right: 20px;
      object-fit: contain;
    }
  }
  .image-left-section {
    // background-color: #e5a36f;
    width: 40%;
    // height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    // max-height: 90vh;

    // background: var(--white);
    // overflow: hidden;
    // background-color: #00ccff;
    @media (max-width: 768px) {
      // background-color: white;
      // border-radius: 30px;
      // margin: 24px;
      margin-top: 0;
      position: absolute;
      // flex-direction: column;
      // display: flex;
      top: -0px;
      // left: 0px;
      width: 70%;
      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }

  .carousel-button {
    display: flex;
    background-color: white;
    align-items: center;
    width: fit-content;
    height: 75px;

    // gap: 8px;
    // font-size: 24px;
    // width: 224px;
    justify-content: center;
    padding: 24px 20px;
    border-radius: 50px;
    position: relative;
    // top: 24px;
    // left: 50%;
    // transform: translateX(-50%);
    font-weight: 600;

    @media (max-width: 768px) {
      height: 30px;
      margin-top: 16px;
    }

    .image-carousel-button {
      all: unset;
      position: relative;
      height: 48px;
      width: 48px;
      @media (max-width: 768px) {
        height: 30px;
        width: 30px;
      }
    }
  }

  h2 {
    padding-left: 10px;
    @media (min-width: 1266px) {
      // width: 1240px;
    }

    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    // line-height: 75px;
    // margin-bottom: 96px;

    @media (max-width: 768px) {
      font-size: 18px;
      text-align: center;
      width: 100%;

      line-height: 36px;
      margin-bottom: 0px;
    }
  }

  p {
    text-align: center;
    margin: 10px 12px;
    font-style: normal;
    font-weight: 400;
    // font-size: 32px;
    font-size: 35px;
    // line-height: 48px;
    // background-color: red;
    letter-spacing: 0.01em;
    // padding: 0;

    span {
      text-transform: uppercase;
      font-weight: bold;
    }

    @media (max-width: 768px) {
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 20px;
      text-align: center;
      width: 100%;
    }
  }
}
