@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #192830;
}
body,
html {
  // overflow: hidden;

  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}
button {
  all: unset;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Custom scrollbar for webkit-based browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

/* Custom scrollbar for Mozilla Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollbar-thumb:hover {
  background-color: #555;
}
