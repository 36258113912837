.footer {
  // background-color: #EAF7FF;
  width: 100%;
  // height: 480px;

  font-family: "Poppins", sans-serif;
  padding-bottom: 64px;
  padding: 12px 0;
  padding: 36px 24px;
  padding-bottom: 48px;

  @media (max-width: 1024px) {
    height: fit-content;
    padding: 12px 16px;
  }

  .content {
    height: 100%;
    background-color: #fff;
    border-radius: 24px;
    color: #192830;
    overflow: hidden;
    padding: 12px 32px;
    padding-bottom: 0;

    @media (max-width: 768px) {
      padding: 12px;
    }

    .footer-up {
      height: 80%;
      padding-bottom: 12px;

      width: 100%;
      display: grid;
      grid-template-areas: "logo logo list list list apps apps";
      overflow: hidden;
      border-bottom: 1px solid #eee;
      grid-template-columns: 324px 1fr 1fr 1fr 250px;

      @media (max-width: 1024px) {
        grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
        display: flex;
        flex-direction: column;
      }

      @media (min-width: 1024px) and (max-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
      }

      @media (min-width: 1440px) {
        grid-template-columns: 424px 1fr 1fr 1fr 350px;
      }

      .footer-logo {
        grid-area: "logo";
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 1024px) and (max-width: 1440px) {
          img {
            width: 124px;
          }
        }

        @media (max-width: 768px) {
          justify-content: flex-start;
          padding: 24px 0;

          img {
            height: 64px;
          }
        }
      }

      .footer-list {
        grid-area: "list";
        padding: 24px 0;
        height: 100%;

        @media (max-width: 768px) {
          padding: 0;
        }

        h2 {
          font-size: 24px;
          padding: 16px 0;
        }

        a {
          display: inline-block;
          white-space: nowrap;
          padding: 8px 0;
        }
      }

      .footer-apps {
        grid-area: "apps";
        // background-color: #000;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          justify-content: flex-start;
          border-top: 1px solid #eee;
          margin-top: 18px;
        }

        .available-store {
          @media (max-width: 768px) {
            margin-top: 12px;

            // padding-bottom: 24px;
          }

          .app-store-link {
            cursor: pointer;
          }

          .app-store {
            padding: 16px 32px;
            margin: 12px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            background-color: #000;
            cursor: pointer;

            @media (max-width: 768px) {
              min-width: 300px;
              padding: 16px 0px;
              margin: 12px 0;
              padding-left: 12px;
              justify-content: center;
              padding: 12px 0;
            }

            .text {
              height: 32px;
              display: flex;
              flex-direction: column;

              @media (max-width: 768px) {
                width: 124px;
              }

              p {
                text-transform: uppercase;
                font-size: 12px;
                line-height: 12px;
                color: #fff !important;
              }

              h4 {
                font-weight: 400;
                color: white;
                font-weight: 500;
              }
            }
          }

          img {
            height: 32px;
            width: 32px;
          }
        }
      }
    }

    .footer-copyright {
      height: 20%;
      // height: 100%;
      padding: 14px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding-top: 0;
      }
      // background-color: aqua;
      // margin-bottom: ;

      p {
        white-space: nowrap;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 1024px) {
          // display: flex;
          // flex-direction: column;
          // align-items: flex-start;
          display: block;
          white-space: normal;
        }

        span {
          position: relative;
          padding: 0 24px;
          text-align: center;

          @media (max-width: 1024px) {
            display: inline-block;
          }

          &::after {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: #000;
            left: 12px;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 768px) {
              display: block;
            }
          }

          &::before {
            display: none;
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: #000;
            right: 0px;
            top: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 768px) {
              // right: -24px;
              display: none;
            }
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }

      .copyright-socials {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 12px;

        h3 {
          white-space: nowrap;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: start;
          width: 100%;
          padding: 24px 0;
        }

        .footer-social {
          display: flex;
          gap: 12px;

          .social {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            a {
              all: unset;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 24px;
                width: 24px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}

#offre-emploies {
  position: relative;
}

#bientot {
  /* Bleu-Gray */

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 12px;
  padding: 4px 12px;
  position: absolute;
  right: -75%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #192830;
}

#nos-services-footer {
  position: relative;
  padding-right: 12px;
}

#terms {
  margin: 12px 0;
}
