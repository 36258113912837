.partners {
  margin-top: 48px;
  width: 75%;
  font-family: "Poppins", sans-serif;
  // overflow-x: overlay;
  // overflow-x: hidden;
  // position: relative;
  background-color: white;
  border-radius: 24px;
  margin: 36px auto;

  // padding: 16px 0;
  @media (max-width: 768px) {
    // margin: 36px 16px;
    width: 90%;
  }

  .scroller {
    // max-width: 600px;
  }

  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 10%,
      white 90%,
      transparent
    );
    mask: linear-gradient(
      90deg,
      transparent,
      white 10%,
      white 90%,
      transparent
    );
  }

  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 100s)
      var(--_animation-direction, forwards) linear infinite;
  }

  .scroller[data-direction="right"] {
    --_animation-direction: reverse;
  }

  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }

  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }

  .scroller[data-speed="slow"] {
    --_animation-duration: 100s;
  }

  @keyframes scroll {
    to {
      transform: translate(calc(-100% - 0.5rem));
    }
  }

  /* general styles */

  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }

  .tag-list li {
    padding: 1rem;
    background: var(--clr-primary-400);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
  }

  /* for testing purposed to ensure the animation lined up correctly */
  .test {
    background: red !important;
  }

  .container {
    padding-top: 25px;
    // padding: 24px 0;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
    // background-color: black;

    // pointer-events: none;
    // margin: 24px;
    @media (max-width: 768px) {
      // padding: 24px 0;
      // background-color: white;
      font-family: "Poppins", sans-serif;
      overflow-x: hidden;
      border-radius: 20px;
      // margin: 24px;
    }
  }
  .title-container {
    padding-top: 20px;
    // padding: 24px 0;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
    // background-color: black;

    pointer-events: none;
    // margin: 24px;
    @media (max-width: 768px) {
      // padding: 24px 0;
      // background-color: white;
      font-family: "Poppins", sans-serif;
      overflow-x: hidden;
      border-radius: 20px;
      // margin: 24px;
    }
  }

  .tabPanel {
    font-family: "Poppins", sans-serif;
    display: flex;

    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
  .carousel-content {
    // width: "fit-content";
    display: flex;
  }
  .box-motion-div-carousel {
    // overflow-y: hidden;
    margin: 0 12px;
    // padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;

    @media (max-width: 768px) {
    }
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    // line-height: 48px;
    letter-spacing: 0.01em;
    font-weight: bold;
    margin: 24px;
    margin-top: 48px;

    @media (max-width: 1024px) {
      font-size: 32px;
      position: relative;
      left: -12px;
    }
  }
  p {
    font-size: 2.5rem;
    font-weight: 500;

    text-align: center;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    // font-size: 32px;
    @media (max-width: 1024px) {
      font-size: 2rem;
    }
    // line-height: 48px;
    letter-spacing: 0.01em;
    padding: 16px;

    span {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    // line-height: 48px;
    letter-spacing: 0.01em;
    font-weight: bold;
    margin: 24px;
    margin-top: 48px;

    @media (max-width: 1024px) {
      font-size: 32px;
      position: relative;
      left: -12px;
    }
  }
  h3 {
    // font-style: normal;
    // font-weight: 200;
    font-size: 16px;
    // line-height: 48px;
    // letter-spacing: 0.01em;
    font-weight: normal;
    // margin: 16px;
    // text-align: center;
  }
  h4 {
    // font-style: normal;
    // font-weight: 200;
    font-size: 16px;
    // line-height: 48px;
    // letter-spacing: 0.01em;
    font-weight: normal;
    // margin: 16px;

    // margin-left: 16px;
    // background-color: #3fcebe;
    // padding-left: 25px;
    // margin: 8px;
    text-align: center !important;
  }
  .title-carousel-item {
    // background-color: #c9faf4;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    width: 200px;
  }

  .grid-container {
    padding-left: 8px;
  }

  .item-div-carousel {
    // margin: 5px 6px;
    // margin: 16px;
    width: 200px;
    // padding: 0px !important;
    display: flex !important;
    // justify-content: center;
    // align-items: center;
    // width: 100px;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    // margin: 24px 0px !important;

    padding: 18px;
    // width: 100%;
    // height: 300px;
    // width: 200px;
    // background-color: rgb(15, 141, 195);
  }

  .box-carousel {
    // display: flex;
    margin-bottom: 16px;
    // margin-left: 30px;
    // position: absolute;
    padding: 4px;
    // flex-direction: column;
    // height: 150px;
    align-self: center;
    // overflow-y: hidden;
    border-radius: 15px;
    height: 100px;
    width: 100px;
    background-color: white;
    box-shadow: 1px 1px 15px 5px rgba(13, 19, 33, 0.1);
    @media (max-width: 1024px) {
      height: 100px;
      width: 100px;
    }
  }
  .box-inner-carousel {
    display: flex;

    flex-direction: column;

    overflow-y: hidden;
    border-radius: 15px;
  }

  /* square buttons */
  .rec.rec-arrow {
    border-radius: 50%;
    background-color: black;
  }
  /* round buttons on hover */
  .rec.rec-arrow:hover {
    border-radius: 50%;

    background-color: black;
  }
  /* hide disabled buttons */
  .rec.rec-arrow:disabled {
    visibility: hidden;
  }
  /* disable default outline on focused items */
  /* add custom outline on focused items */
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
  }

  .carousel-buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    // padding: 12px 6px;
    // gap: 8px;
    // left: -32px;
    z-index: 9999999999999;
    left: -8px;
    @media (max-width: 768px) {
      left: 0px;
    }

    button {
      // background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .btnHead {
    // background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    // width: 100%;
    border-radius: 25px;
    @media (max-width: 768px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
    img {
      height: 24px;
      width: 24px;
    }
    .pHead {
      color: black;
      font-size: 12;
      // font-weight: 900;
    }
  }

  .tapered {
    text-align: left;
    background-image: linear-gradient(
      179deg,
      #3fcebe 100%,
      #3fcebe 100%,
      transparent 100%,
      transparent 100%
    );
  }
  .taperedSrv {
    text-align: left;
    background-image: linear-gradient(
      179deg,
      #ff7a48 100%,
      #ff7a48 100%,
      transparent 100%,
      transparent 100%
    );
  }
  .tapered2 {
    background-image: linear-gradient(
      181deg,
      #3fcebe 0%,
      #c9faf4 50%,
      transparent 54%,
      transparent 100%
    );
  }

  h1 {
    margin-bottom: 3.5rem;
    span {
      background-size: 100% 15%;
      background-repeat: repeat-x;
      background-position: left 0% bottom 10%;
    }
  }
  img {
  }

  .counters {
    align-items: center;
    justify-content: center;
    // background-color: #3fcebe;
    overflow: hidden;
    width: 100%;
  }
  .counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    // background-color: red;
  }
  .counter-div {
    align-items: center !important;
    justify-content: center !important;
    // background-color: red;
    width: 25%;
    margin: 16px;
    padding: 30px;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }
  .counter-img {
    align-items: center !important;
    justify-content: center !important;
    // background-color: white;
    display: flex !important;
    // width: 48px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  // .buttons {
  //     height: 100%;
  //     display: flex;
  //     align-items: center;
  //     gap: 12px;
  //     justify-content: flex-end;
  //     margin: 24px 0;

  //     .slider-button {
  //         background-color: #000;
  //         width: 48px;
  //         height: 48px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         border-radius: 50%;
  //         cursor: pointer;

  //         img {
  //             height: 24px;
  //             width: 32px;

  //             @media (max-width:768px) {
  //                 height: 18px;
  //                 width: 24px;
  //             }
  //         }
  //     }

  // }
}
