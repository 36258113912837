.download {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: #fff;
  border-radius: 12px;

  display: none;
  color: white !important;
  // overflow-x: scroll;

  @media (max-width: 768px) {
    display: flex;
  }

  button {
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    gap: 12px;
    border-radius: 50px;

    span {
      color: white !important;
    }

    #telecharger {
      font-weight: 600;
    }

    img {
      height: 24px !important;
      width: 24px !important;
      position: static !important;
    }
  }

  .stores {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    color: white !important;
    margin-top: 6px;
    button {
      gap: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: white !important;
      padding: 6px 0;

      // div {
      //     display: flex;
      //     flex-direction: column;
      //     // padding: 0 6px;

      //     p {
      //         font-size: 8px;
      //         line-height: 12px;
      //         opacity: 0.85;
      //         white-space: nowrap;
      //         color: white !important;
      //         padding-right: 6px;

      //     }

      //     span {
      //         white-space: nowrap;
      //         line-height: 12px;
      //         font-size: 12px;
      //         color: white !important;

      //     }

      // }

      img {
        height: 100% !important;
        width: 90% !important;
      }
    }
  }

  .stores {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    color: white !important;
    margin-top: 6px;

    .app-store-link {
      button {
        gap: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: white !important;
        padding: 6px 0;
        min-height: 35px;

        img {
          height: 90% !important;
          width: 80% !important;
        }
      }
    }
  }
}
