.funds {
  background-color: white;
  // height: 100vh;
  padding-top: 128px;
  // overflow: hidden;
  font-family: "Poppins";

  @media (max-width: 768px) {
    padding-top: 64px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding: 64px;
    height: 560px;

    @media (max-width: 768px) {
      padding: 64px 24px;
      flex-direction: column;
      gap: 32px;
      height: fit-content;
    }

    @media (min-width: 1440px) {
      justify-content: space-evenly;
    }

    .text {
      padding-right: 32px;

      h3 {
        margin-bottom: 12px;
      }

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 96px;
        margin: 12px 0;
        white-space: nowrap;

        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 48px;
        }

        @media (min-width: 1440px) {
          line-height: 82px;
        }
      }

      p {
        width: 75%;
        line-height: 32px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .image {
      height: 100%;

      @media (max-width: 768px) {
        width: 100%;
      }

      img {
        height: 100%;

        @media (max-width: 768px) {
          width: 100%;
          object-fit: cover;
        }
      }

      p {
        display: none;

        @media (max-width: 768px) {
          display: block;
          width: 100%;
          line-height: 32px;
          padding: 24px 0;
        }
      }
    }
  }

  section {
    padding: 24px 0;

    @media (max-width: 768px) {
      padding: 0px 24px;
    }

    h2 {
      text-align: center;
      font-size: 32px;

      @media (max-width: 768px) {
        text-align: start;
        padding: 24px 0;
      }
    }

    img {
      height: 96px;
      display: block;
      margin: 24px auto;

      @media (max-width: 768px) {
        margin: 0px;
        width: 100%;
        height: fit-content;
        max-height: 96px;
      }
    }

    p {
      padding: 16px;
      width: 85%;
      text-align: start;
      margin: 0 auto;
      line-height: 36px;
      font-size: 20px;

      @media (max-width: 768px) {
        text-align: start;
        padding: 24px 0px;
        width: 100%;
        font-size: 16px;
      }
    }
  }

  .funds-label {
    display: flex;
    padding: 48px 32px;
    justify-content: center;

    @media (min-width: 1440px) {
      height: 100%;
      height: 500px;
      padding: 0;
      margin: 24px 0;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }

    .label-images {
      // height: 300px;
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      width: fit-content;

      @media (max-width: 768px) {
        padding: 0;
        width: 100%;
      }

      h2 {
        display: none;
        padding: 16px 0;

        @media (max-width: 768px) {
          display: block;

          text-align: center;
          font-size: 18px;
          padding: 18px 0;
        }
      }

      img {
        width: 100%;
        max-height: 250px;

        @media (max-width: 768px) {
        }
      }

      .label-1 {
        background-color: #b9ffdc;
        display: flex;
        // height: 248px;
        border-radius: 36px;
        justify-content: space-around;
        align-items: center;
        padding: 12px;
        width: 444px;

        @media (max-width: 768px) {
          width: 100%;
        }

        #label-image-1 {
          height: 100%;
          width: 40%;
          object-fit: contain;
        }

        #label-image-2 {
          height: 148px;
          width: 45%;
          object-fit: contain;
        }
      }

      .label-2 {
        padding: 24px;
        border-radius: 36px;
        width: 444px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../../../assets/label-4.webp);
        background-size: cover;

        @media (max-width: 768px) {
          width: 100%;
        }

        #label-image-2 {
          height: 124px;
          width: 124px;
        }
      }
    }

    .label-text {
      width: 50%;
      padding: 0 32px;

      @media (max-width: 768px) {
        padding: 12px 0;
        width: 100%;
      }

      h2 {
        @media (max-width: 768px) {
          display: none;
        }
      }

      p {
        padding: 16px 0;
        text-align: start;
        margin: 0 auto;
        font-size: 22px;
        line-height: 36px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 32px;
          padding: 16px 0;
        }
      }
    }
  }

  .contact-cta {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 860px;
    padding: 36px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    h2 {
      font-size: 36px;
      text-align: center;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 24px;
        text-align: center;
      }
    }

    button {
      background-color: #baf8f0;
      color: #3fcebe;
      padding: 12px 32px;
      border-radius: 24px;
      width: 224px;
      text-align: center;
    }
  }
}

.mobile-footer {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.desk-footer {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

#vision-image {
  @media (max-width: 768px) {
    width: fit-content;
    height: 124px;
  }
}
